var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.assigningStudents
    ? _c("base-toolbar", {
        attrs: { searchable: "", search: _vm.searchText },
        on: {
          "update:search": function($event) {
            _vm.searchText = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "mobile-search-filters",
              fn: function() {
                return [
                  _c("search-filter", {
                    ref: "searchFilter",
                    attrs: {
                      filterCount: _vm.filterCount,
                      isFiltered: _vm.isFiltered,
                      clearFilter: _vm.clearFilter,
                      mobileMode: true
                    },
                    on: { focusMenu: _vm.focusMenu },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "filters-menu",
                          fn: function() {
                            return [
                              _c("v-select", {
                                ref: "firstMenuItem",
                                staticClass: "ml-2",
                                staticStyle: { "max-width": "195px" },
                                attrs: {
                                  label: _vm.$t("gradeLevelLabel"),
                                  "display:inline-flex": "",
                                  "return-object": "",
                                  "hide-details": "auto",
                                  items: _vm.gradesList,
                                  "item-text": "text",
                                  outlined: "",
                                  dense: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "escape",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.focusButton.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.showLevel,
                                  callback: function($$v) {
                                    _vm.showLevel = $$v
                                  },
                                  expression: "showLevel"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      4086785486
                    )
                  })
                ]
              },
              proxy: true
            },
            {
              key: "toolbar-right",
              fn: function() {
                return [
                  !_vm.editMode
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "ml-2 my-1",
                          attrs: {
                            color: "primary",
                            label:
                              _vm.$t("addLabel") + " " + _vm.$t("studentLabel"),
                            text: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.confirmAddStudent.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      )
                    : [
                        _vm.selectedRows.length > 0
                          ? _c(
                              "v-menu",
                              {
                                attrs: {
                                  bottom: "",
                                  "close-on-click": true,
                                  "close-on-content-click": false,
                                  maxWidth: 200
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var onMenu = ref.on
                                        return [
                                          _c(
                                            "pb-btn",
                                            _vm._g(
                                              {
                                                ref: "actionsButton",
                                                staticClass: "ml-2 my-1",
                                                attrs: {
                                                  color: "primary",
                                                  label: _vm.$t("tagsLabel"),
                                                  text: "",
                                                  outlined: ""
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.focusStatus(0)
                                                  }
                                                }
                                              },
                                              Object.assign({}, onMenu)
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-tags")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3330609038
                                )
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.stop",
                                        modifiers: { stop: true }
                                      }
                                    ],
                                    staticClass: "white student-tags pa-2",
                                    attrs: { tabindex: "0" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pr-2" },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "ma-0",
                                            attrs: { justify: "center" }
                                          },
                                          _vm._l(_vm.allTags, function(
                                            tag,
                                            index
                                          ) {
                                            return _c(
                                              "pb-btn",
                                              {
                                                key: tag.studentTagId,
                                                ref: "status" + index,
                                                refInFor: true,
                                                attrs: {
                                                  label: tag.tagText,
                                                  icon: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addRemoveTag(
                                                      tag,
                                                      _vm.selectedRows
                                                    )
                                                  },
                                                  keyup: [
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.addRemoveTag(
                                                        tag,
                                                        _vm.selectedRows
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "down",
                                                          40,
                                                          $event.key,
                                                          ["Down", "ArrowDown"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusActionButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "up",
                                                          38,
                                                          $event.key,
                                                          ["Up", "ArrowUp"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusActionButton.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "right",
                                                          39,
                                                          $event.key,
                                                          [
                                                            "Right",
                                                            "ArrowRight"
                                                          ]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 2
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusStatus(
                                                        index + 1
                                                      )
                                                    },
                                                    function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "left",
                                                          37,
                                                          $event.key,
                                                          ["Left", "ArrowLeft"]
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      if (
                                                        "button" in $event &&
                                                        $event.button !== 0
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.focusStatus(
                                                        index - 1
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: { color: tag.color },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.tagSelected(
                                                        tag,
                                                        _vm.selectedRows
                                                      )
                                                        ? "fas fa-check-circle"
                                                        : "fas fa-circle"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "pb-btn",
                                          {
                                            ref: "manageStatusButton",
                                            attrs: {
                                              icon: "",
                                              label: _vm.$t("manageTagsLabel")
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.dialog.tagsEditor = true
                                              },
                                              keyup: [
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  _vm.dialog.tagsEditor = true
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "left",
                                                      37,
                                                      $event.key,
                                                      ["Left", "ArrowLeft"]
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  if (
                                                    "button" in $event &&
                                                    $event.button !== 0
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.focusStatus(
                                                    _vm.allTags.length - 1
                                                  )
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "down",
                                                      40,
                                                      $event.key,
                                                      ["Down", "ArrowDown"]
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.focusActionButton.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "up",
                                                      38,
                                                      $event.key,
                                                      ["Up", "ArrowUp"]
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.focusActionButton.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              ]
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("fal fa-cog")])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showDeleteButton
                          ? _c(
                              "pb-btn",
                              {
                                staticClass: "ml-2 my-1",
                                attrs: {
                                  color: "error",
                                  label: _vm.$t("deleteLabel"),
                                  text: "",
                                  outlined: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.deleteMultiplePopup.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "pb-btn",
                          {
                            staticClass: "ml-2 my-1",
                            attrs: {
                              color: "primary",
                              label: _vm.$t("doneLabel"),
                              text: "",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.toggleEditMode.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "done-icon" }, [
                              _vm._v("fal fa-pen-slash")
                            ])
                          ],
                          1
                        )
                      ],
                  !_vm.editMode
                    ? [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              bottom: "",
                              "offset-y": "",
                              "close-on-click": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var onMenu = ref.on
                                    return [
                                      _c(
                                        "pb-btn",
                                        _vm._g(
                                          {
                                            staticClass: "ml-2 my-1",
                                            attrs: {
                                              color: "primary",
                                              label: _vm.$t("actionsLabel"),
                                              text: "",
                                              outlined: ""
                                            }
                                          },
                                          Object.assign({}, onMenu)
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "fal fa-ellipsis-stroke-vertical"
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              648777904
                            )
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.toggleEditMode } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "justify-start",
                                        staticStyle: { width: "100%" },
                                        attrs: { text: "", color: "primary" }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-pen-to-square")
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(_vm.$t("manageLabel")))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.assigningStudents = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "justify-start",
                                        staticStyle: { width: "100%" },
                                        attrs: { color: "primary", text: "" }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-user-check")
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(_vm.$t("assignLabel")))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.openImport } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "justify-start",
                                        staticStyle: { width: "100%" },
                                        attrs: { color: "primary", text: "" }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-upload")
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(_vm.$t("importLabel")))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.dialog.joinCodes = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "justify-start",
                                        staticStyle: { width: "100%" },
                                        attrs: { color: "primary", text: "" }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("fal fa-barcode")
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("generateCodesLabel"))
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.$currentUser.isAdmin
                                  ? _c(
                                      "v-list-item",
                                      { on: { click: _vm.openPromote } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "justify-start",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              color: "primary",
                                              text: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("promoteLabel"))
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "toolbar-left",
              fn: function() {
                return [
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
                    [
                      _c("search-filter", {
                        ref: "searchFilter",
                        attrs: {
                          filterCount: _vm.filterCount,
                          isFiltered: _vm.isFiltered,
                          clearFilter: _vm.clearFilter,
                          searchText: _vm.searchText,
                          searchWidthGrow: _vm.searchWidthGrow,
                          mobileMode: false
                        },
                        on: {
                          "update:searchText": function($event) {
                            _vm.searchText = $event
                          },
                          "update:search-text": function($event) {
                            _vm.searchText = $event
                          },
                          focusMenu: _vm.focusMenu
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "filters-menu",
                              fn: function() {
                                return [
                                  _c("v-select", {
                                    ref: "firstMenuItem",
                                    staticClass: "ml-2",
                                    staticStyle: { "max-width": "195px" },
                                    attrs: {
                                      label: _vm.$t("gradeLevelLabel"),
                                      "display:inline-flex": "",
                                      "return-object": "",
                                      "hide-details": "auto",
                                      items: _vm.gradesList,
                                      "item-text": "text",
                                      outlined: "",
                                      dense: ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "escape",
                                            undefined,
                                            $event.key,
                                            undefined
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.focusButton.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.showLevel,
                                      callback: function($$v) {
                                        _vm.showLevel = $$v
                                      },
                                      expression: "showLevel"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4086785486
                        )
                      })
                    ],
                    1
                  ),
                  _vm.$currentUser.isDistrictAdmin || _vm.haveMultipleSchools
                    ? _c("v-autocomplete", {
                        staticClass: "ml-2",
                        staticStyle: {
                          "max-width": "190px",
                          "min-width": "190px"
                        },
                        attrs: {
                          label: _vm.$t("schoolLabel"),
                          "display:inline-flex": "",
                          "hide-details": "auto",
                          items: _vm.schoolItems,
                          outlined: "",
                          dense: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c("span", [_vm._v(_vm._s(data.item.text))]),
                                  _c("rostered-item-indicator", {
                                    key:
                                      data.item.value +
                                      data.item.isItemRostered,
                                    attrs: {
                                      itemType: "school",
                                      item: data.item
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          901267436
                        ),
                        model: {
                          value: _vm.currentSchoolId,
                          callback: function($$v) {
                            _vm.currentSchoolId = $$v
                          },
                          expression: "currentSchoolId"
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          3441405656
        )
      })
    : _c("base-toolbar", {
        attrs: { searchable: false, search: _vm.searchText },
        on: {
          "update:search": function($event) {
            _vm.searchText = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "toolbar-left",
            fn: function() {
              return [
                _vm.$currentUser.isTeacher
                  ? _c("v-select", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.localIsMenuOverlapping,
                          expression: "!localIsMenuOverlapping"
                        }
                      ],
                      staticStyle: { "max-width": "180px" },
                      attrs: {
                        label: _vm.$t("classLabel"),
                        dense: "",
                        "display:inline-flex": "",
                        "return-object": "",
                        "hide-details": "auto",
                        items: _vm.classList,
                        outlined: ""
                      },
                      model: {
                        value: _vm.selectedClass,
                        callback: function($$v) {
                          _vm.selectedClass = $$v
                        },
                        expression: "selectedClass"
                      }
                    })
                  : _c("school-year-selector", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.localIsMenuOverlapping,
                          expression: "!localIsMenuOverlapping"
                        }
                      ],
                      staticClass: "ml-2",
                      attrs: { warnPastYears: "" }
                    }),
                !_vm.localIsMenuOverlapping
                  ? _c("v-select", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isMenuOverlapping(),
                          expression: "!isMenuOverlapping()"
                        }
                      ],
                      staticClass: "ml-2",
                      staticStyle: { "max-width": "180px" },
                      attrs: {
                        label: _vm.$t("gradeLevelLabel"),
                        dense: "",
                        "display:inline-flex": "",
                        "return-object": "",
                        "hide-details": "auto",
                        items: _vm.gradesList,
                        "item-text": "text",
                        outlined: "",
                        id: "studentsGradeLevelSelect"
                      },
                      model: {
                        value: _vm.showLevel,
                        callback: function($$v) {
                          _vm.showLevel = $$v
                        },
                        expression: "showLevel"
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "toolbar-right",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }