






























































































































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component } from 'vue-property-decorator';
import SchoolYearSelector from '../core/SchoolYearSelector.vue';
import CommonUtils from '@/utils/common-utils';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';
import SearchFilter from '@/components/core/SearchFilter.vue';

@Component({
  components: {
    BaseToolbar,
    SchoolYearSelector,
    RosteredItemIndicator,
    SearchFilter
  }
})
export default class StudentsToolbar extends BaseToolbar {
  searchWidthGrow = '0';

  $refs!: {
    searchFilter: SearchFilter
  }

  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get studentAssignmentComponent() {
    return this.localComponent.studentAssignment || {};
  }

  get doneAssigning() {
    return this.studentAssignmentComponent.doneAssigning || this.emptyFunction;
  }

  get confirmAddStudent() {
    return this.localComponent.confirmAddStudent || this.emptyFunction;
  }

  get toggleEditMode() {
    return this.localComponent.toggleEditMode || this.emptyFunction;
  }

  get editMode() {
    return this.localComponent.editMode;
  }

  get openImport() {
    return this.localComponent.openImport || this.emptyFunction;
  }

  get assigningStudents() {
    return this.localComponent.assigningStudents;
  }

  set assigningStudents(value: boolean) {
    this.localComponent.assigningStudents = value;
    if (value) {
      this.$router.replace('/assign-students');
    }
  }

  get openPromote() {
    return this.localComponent.openPromote || this.emptyFunction;
  }

  get dialog() {
    return this.localComponent.dialog || {};
  }

  get selectedRows() {
    return this.localComponent.selectedRows || [];
  }

  get showTagGroup() {
    return this.localComponent.showTagGroup || this.emptyFunction;
  }

  get showDeleteButton() {
    return this.localComponent.showDeleteButton || false;
  }

  get deleteMultiplePopup() {
    return this.localComponent.deleteMultiplePopup || this.emptyFunction;
  }

  get gradesList() {
    return this.localComponent.gradesList || [];
  }

  get showLevel() {
    return this.localComponent.showLevel;
  }

  set showLevel(value: boolean) {
    this.localComponent.showLevel = value;
  }

  get selectedClass() {
    return this.studentAssignmentComponent.selectedClass;
  }

  set selectedClass(value: any) {
    this.studentAssignmentComponent.selectedClass = value;
  }

  get classList() {
    return this.studentAssignmentComponent.classList || [];
  }

  get searchText() {
    return this.localComponent.search || '';
  }

  set searchText(value: string) {
    this.localComponent.search = value;
  }

  get isMenuOverlapping() {
    return this.studentAssignmentComponent.isMenuOverlapping || this.emptyFunction;
  }

  get localIsMenuOverlapping() {
    return this.studentAssignmentComponent.localIsMenuOverlapping || false;
  }

  get haveMultipleSchools():boolean {
    return this.localComponent.haveMultipleSchools || false;
  }

  get schoolItems() {
    return this.localComponent.schoolItems || [];
  }

  get currentSchoolId() {
    return this.localComponent.currentSchoolId;
  }

  set currentSchoolId(val:any) {
    this.localComponent.currentSchoolId = val;
  }

  get allTags() {
    return this.localComponent.allTags || [];
  }

  get tagSelected() {
    return this.localComponent.tagSelected || this.emptyFunction;
  }

  get addRemoveTag() {
    return this.localComponent.addRemoveTag || this.emptyFunction;
  }

  focusStatus(index: number) {
    try {
      if (index === -1) {
        this.focusActionButton();
      } else if (index >= this.allTags.length) {
        (this.$refs as any).manageStatusButton.$el.querySelector('.v-btn').focus();
      } else {
        (this.$refs as any)['status' + index][0].$el.querySelector('.v-btn').focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusActionButton() {
    try {
      (this.$refs as any).actionsButton.$el.querySelector('.v-btn').focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  get isFiltered() {
    if (CommonUtils.hasNoText(this.searchText) && this.showLevel === this.gradesList[0]) {
      this.searchWidthGrow = '0';
      return false;
    } else {
      this.searchWidthGrow = '40';
      return true;
    }
  }

  get filterCount() {
    var count = 0;
    if (CommonUtils.hasText(this.searchText)) {
      count += 1;
    }
    if (this.showLevel !== this.gradesList[0]) {
      count += 1;
    }
    return count;
  }

  clearFilter() {
    this.searchText = '';
    this.showLevel = this.gradesList[0];
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).firstMenuItem.$el.querySelector('input').focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }
}
